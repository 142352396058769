import { callAndWaitForTransfer } from "@cyberpnk/component-library";
import { getBtcNftContract, getWbtcContract } from "../../app/common";

declare global {
  interface Window { ethereum: any; }
}

export const getTotalLocked = async () => {
  const nftFaucetContract = await getBtcNftContract();
  return nftFaucetContract.methods.totalSatoshisLocked().call();
}

export const getTotalSacrificed = async () => {
  const nftFaucetContract = await getBtcNftContract();
  return nftFaucetContract.methods.totalSatoshisSacrificed().call();
}

export const mintLockingWbtc = async (sats: string) => {
  return callAndWaitForTransfer(getBtcNftContract, "mintLockingWbtc", [sats]);
}

export const unlockWbtcBurningNft = async (itemId: string) => {
  return callAndWaitForTransfer(getBtcNftContract, "unlockWbtcBurningNft", [itemId], getWbtcContract);
}

export const mintScrificingWbtc = async (sats: string) => {
  return callAndWaitForTransfer(getBtcNftContract, "sacrificeWbtcForNftDoNotDoThisYouLoseMoney", [sats]);
}

