import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { connectWalletAsync, mintSuccess, newWalletConnected, SimpleAsyncState, walletDisconnected } from '@cyberpnk/component-library';
import { getTotalLocked, getTotalSacrificed, mintLockingWbtc, mintScrificingWbtc, unlockWbtcBurningNft } from './btcNftAPI';

export interface NftFaucetState {
  totalLocked: SimpleAsyncState<string>;
  totalSacrificed: SimpleAsyncState<string>;
  mintLockingWbtc: SimpleAsyncState<void>;
  unlockWbtcBurningNft: SimpleAsyncState<void>;
  mintScrificingWbtc: SimpleAsyncState<void>;
}

const initialState: NftFaucetState = {
  mintLockingWbtc: {
    loading: false,
  },
  unlockWbtcBurningNft: {
    loading: false,
  },
  mintScrificingWbtc: {
    loading: false,
  },
  totalLocked: {
    loading: false,
  },
  totalSacrificed: {
    loading: false,
  }
};

export const getTotalLockedAsync = createAsyncThunk(
  'nftFaucet/getTotalLocked',
  async (args, thunkAPI) => {
    const total = await getTotalLocked();
    return total;
  }
);

export const getTotalSacrificedAsync = createAsyncThunk(
  'nftFaucet/getTotalSacrificed',
  async (args, thunkAPI) => {
    const total = await getTotalSacrificed();
    return total;
  }
);

export const mintLockingWbtcAsync = createAsyncThunk(
  'nftFaucet/mintLockingWbtc',
  async ({ sats }: { sats: string }, thunkAPI) => {
    await mintLockingWbtc(sats);
  }
);

export const unlockWbtcBurningNftAsync = createAsyncThunk(
  'nftFaucet/unlockWbtcBurningNft',
  async ({ itemId }: { itemId: string }, thunkAPI) => {
    await unlockWbtcBurningNft(itemId);
  }
);

export const mintScrificingWbtcAsync = createAsyncThunk(
  'nftFaucet/mintScrificingWbtc',
  async ({ sats }: { sats: string }, thunkAPI) => {
    await mintScrificingWbtc(sats);
  }
);

export const btcNftSlice = createSlice({
  name: 'btcNft',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(mintSuccess, (state) => {
      })
      .addCase(mintLockingWbtcAsync.pending, (state) => {
        state.mintLockingWbtc.loading = true;
        state.mintLockingWbtc.error = undefined;
      })
      .addCase(mintLockingWbtcAsync.fulfilled, (state, action) => {
        state.mintLockingWbtc.loading = false;
      })
      .addCase(mintLockingWbtcAsync.rejected, (state, action) => {
        state.mintLockingWbtc.loading = false;
        state.mintLockingWbtc.error = "There was an error with the transaction"
      })
      .addCase(unlockWbtcBurningNftAsync.pending, (state) => {
        state.unlockWbtcBurningNft.loading = true;
        state.unlockWbtcBurningNft.error = undefined;
      })
      .addCase(unlockWbtcBurningNftAsync.fulfilled, (state, action) => {
        state.unlockWbtcBurningNft.loading = false;
      })
      .addCase(unlockWbtcBurningNftAsync.rejected, (state, action) => {
        state.unlockWbtcBurningNft.loading = false;
        state.unlockWbtcBurningNft.error = "There was an error with the transaction"
      })
      .addCase(mintScrificingWbtcAsync.pending, (state) => {
        state.mintScrificingWbtc.loading = true;
        state.mintScrificingWbtc.error = undefined;
      })
      .addCase(mintScrificingWbtcAsync.fulfilled, (state, action) => {
        state.mintScrificingWbtc.loading = false;
      })
      .addCase(mintScrificingWbtcAsync.rejected, (state, action) => {
        state.mintScrificingWbtc.loading = false;
        state.mintScrificingWbtc.error = "There was an error with the transaction"
      })
      .addCase(getTotalLockedAsync.pending, (state) => {
        state.totalLocked.loading = true;
        state.totalLocked.error = undefined;
      })
      .addCase(getTotalLockedAsync.fulfilled, (state, action) => {
        state.totalLocked.response = action.payload;
        state.totalLocked.loading = false;
        state.totalLocked.error = undefined;
      })
      .addCase(getTotalLockedAsync.rejected, (state, action) => {
        state.totalLocked.response = undefined;
        state.totalLocked.loading = false;
        state.totalLocked.error = "There was an error with the transaction";
      })
      .addCase(getTotalSacrificedAsync.pending, (state) => {
        state.totalSacrificed.loading = true;
        state.totalSacrificed.error = undefined;
      })
      .addCase(getTotalSacrificedAsync.fulfilled, (state, action) => {
        state.totalSacrificed.response = action.payload;
        state.totalSacrificed.loading = false;
        state.totalSacrificed.error = undefined;
      })
      .addCase(getTotalSacrificedAsync.rejected, (state, action) => {
        state.totalSacrificed.response = undefined;
        state.totalSacrificed.loading = false;
        state.totalSacrificed.error = "There was an error with the transaction";
      })
      .addCase(connectWalletAsync.pending, (state) => {
        state = initialState;
      })
      .addCase(newWalletConnected, (state) => {
        state = initialState;
      })
      .addCase(walletDisconnected, (state) => {
        state = initialState;
      })
  },
});


export const selectMintLockingWbtcLoading = (state: RootState) => state.btcNft.mintLockingWbtc.loading;
export const selectMintSacrificingWbtcLoading = (state: RootState) => state.btcNft.mintScrificingWbtc.loading;
export const selectPendingTx = (state: RootState) => state.btcNft.mintLockingWbtc.loading || state.btcNft.mintScrificingWbtc.loading || state.btcNft.unlockWbtcBurningNft.loading;
export const selectMintLockingWbtcError = (state: RootState) => state.btcNft.mintLockingWbtc.error;
export const selectMintSacrificingWbtcError = (state: RootState) => state.btcNft.mintScrificingWbtc.error;
export const selectTotalLocked = (state: RootState) => state.btcNft?.totalLocked.response;
export const selectTotalSacrificed = (state: RootState) => state.btcNft?.totalSacrificed.response;

export const btcNftReducer = btcNftSlice.reducer;

export default btcNftSlice.reducer;
