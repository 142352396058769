import React from 'react';
import styles from './App.module.css';
import { useAppSelector, useAppDispatch } from './app/hooks';
import {
  Network, Footer,
  ConnectWallet, selectConnected,
  selectNetwork, Approve,
  selectAllowance, selectFirstTimeCheckAllowance,
  selectApproveLoading, TopBar,
  ApproveStatus, ConnectWalletStatus, EtherscanLink
} from '@cyberpnk/component-library';
import { version } from '../package.json';
import { CONTRACT_ADDRESSES, getBtcNftContract, getWbtcContract, WBTC_CONTRACT_ADDRESSES } from './app/common';
import { BtcNft } from './features/BtcNft/BtcNft';

function App() {
  const connected = useAppSelector(selectConnected);
  const network = useAppSelector(selectNetwork);
  const allowance = useAppSelector(selectAllowance);

  const isKnownNetwork = network === Network.Rinkeby || network === Network.Mainnet;
  const minValue = 0.001;
  const minValueSats = 100000;

  const firstTimeCheckAllowance = useAppSelector(selectFirstTimeCheckAllowance);
  const loading = useAppSelector(selectApproveLoading);

  const needsIncreaseAllowance = !allowance || +allowance < minValueSats;

  const properlyConnected = connected && isKnownNetwork;
  const showConnectStep = !properlyConnected;

  const checkingAllowanceFirstTime = firstTimeCheckAllowance && loading;
  const properlyApproved = properlyConnected && !needsIncreaseAllowance;
  const showApproveStep = properlyConnected && !properlyApproved && !checkingAllowanceFirstTime;

  const showMain = properlyConnected && properlyApproved && !checkingAllowanceFirstTime;

  return (
    <>
      <div className={styles.App}>
        <TopBar>
          {
            connected ? <ApproveStatus getContract={getWbtcContract}
              getSpenderContract={getBtcNftContract}
              useAppDispatch={useAppDispatch}
              useAppSelector={useAppSelector}
              defaultValue={minValue}
              withInput={true}
              minValue={minValue}
              decimals={8}
              symbol={'WBTC'} /> : null
          }
          <ConnectWalletStatus getContract={getBtcNftContract} useAppDispatch={useAppDispatch} useAppSelector={useAppSelector} />
        </TopBar>
        <main>
          <h1>BitCoin is an NFT</h1>
          <h2>BTC wants to be the ultimate store of value, digital art</h2>
          <p className={styles.main}>You can mint a normal token by locking some <EtherscanLink network={network} address={WBTC_CONTRACT_ADDRESSES[network as Network]}>WBTC</EtherscanLink> ({minValue} WBTC minimum), which you can unlock <EtherscanLink network={network} address={CONTRACT_ADDRESSES[network as Network]}>burning the NFT</EtherscanLink>.</p>
          <p className={styles.main}>Or if you really want to lose your WBTC FOREVER, irrecoverably, without recourse, you can mint a special token by burning WBTC ({minValue} WBTC minimum).</p>

          {showConnectStep ? <ConnectWallet getContract={getBtcNftContract} useAppDispatch={useAppDispatch} useAppSelector={useAppSelector} /> : null}
          {showApproveStep ? <>
            <p>First you need to approve our contract to spend your WBTC.  You should only approve the amount you are willing to spend!  The minimum is {minValue} WBTC</p>
            <Approve getContract={getWbtcContract}
              getSpenderContract={getBtcNftContract}
              useAppDispatch={useAppDispatch}
              useAppSelector={useAppSelector}
              defaultValue={minValue}
              minValue={minValue}
              withInput={true}
              decimals={8}
              symbol={'WBTC'} />
          </> : null}
          {showMain ? <div className={styles.main}><BtcNft minValue={minValue} /></div> : null}
        </main>
      </div>
      <div className={styles.footer}>
        <Footer version={version} contractAddress={CONTRACT_ADDRESSES[network as Network]} openSeaCollection={"btc-nft-v2"} network={network} />
      </div>
    </>
  );
}

export default App;
