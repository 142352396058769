import React, { useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  mintLockingWbtcAsync, mintScrificingWbtcAsync,
  selectMintLockingWbtcError, selectMintLockingWbtcLoading,
  selectMintSacrificingWbtcError, selectMintSacrificingWbtcLoading
} from './btcNftFSlice';
import styles from './BtcNft.module.css';
import { ButtonWithInput, EtherscanLink, LoadingOrErrorOrNothing, Network, NftDisplay, selectNetwork } from '@cyberpnk/component-library';
import { CONTRACT_ADDRESSES } from '../../app/common';

export function BtcNft({ minValue }: { minValue: number }) {
  const dispatch = useAppDispatch();

  const mintLockingLoading = useAppSelector(selectMintLockingWbtcLoading);
  const mintLockingError = useAppSelector(selectMintLockingWbtcError);
  const mintSacrificingLoading = useAppSelector(selectMintSacrificingWbtcLoading);
  const mintSacrificingError = useAppSelector(selectMintSacrificingWbtcError);
  const network: Network = useAppSelector(selectNetwork);

  const [inputValueLocking, setInputValueLocking] = useState(minValue);
  const [inputValueSacrificing, setInputValueSacrificing] = useState(minValue);
  const [choice, setChoice] = useState("");
  const noValueLocking = inputValueLocking === undefined || isNaN(inputValueLocking);
  const lessThanMinLocking = !noValueLocking && inputValueLocking !== undefined && inputValueLocking < minValue;
  const invalidInputLocking = noValueLocking || lessThanMinLocking;
  const noValueSacrificing = inputValueSacrificing === undefined || isNaN(inputValueSacrificing);
  const lessThanMinSacrificing = !noValueSacrificing && inputValueSacrificing !== undefined && inputValueSacrificing < minValue;
  const invalidInputSacrificing = noValueSacrificing || lessThanMinSacrificing;
  const disabledInput = mintLockingLoading || mintSacrificingLoading;
  const disabledButtonLocking = mintLockingLoading || mintSacrificingLoading || invalidInputLocking;
  const disabledButtonSacrificing = mintLockingLoading || mintSacrificingLoading || invalidInputSacrificing;
  const btcToSats = (btc: number) => `${btc.toString().replace(/\..*$/, "")}${btc.toString().replace(/^.*\./, "").padEnd(8, '0')}`;

  const errorTextLocking = noValueLocking ? "Enter a value" : lessThanMinLocking ? `Enter at least ${minValue}` : mintLockingError ? mintLockingError : "";
  const errorTextSacrificing = noValueSacrificing ? "Enter a value" : lessThanMinSacrificing ? `Enter at least ${minValue}` : mintSacrificingError ? mintSacrificingError : "";

  return (
    <div className={styles.claim}>
      <p className={choice ? styles.choiceTextHidden : ''}>Now you need to make a choice:</p>
      <div className={`${styles.choices} ${choice ? styles[choice] : ''}`}>
        <div className={styles.firstChoice}>
          <h2>Reversible WBTC to NFT conversion</h2>
          <p>Enter the amount of WBTC you want to lock in exchange for your NFT.  You can get it back later <EtherscanLink network={network} address={CONTRACT_ADDRESSES[network]}>burning the NFT</EtherscanLink>.</p>
          <p><ButtonWithInput cta={true} disabled={disabledButtonLocking} onClick={() => {
            dispatch(mintLockingWbtcAsync({ sats: btcToSats(inputValueLocking) }));
            setChoice("left");
          }} input={{
            cta: true,
            onChange: (value: number) => setInputValueLocking(value),
            disabled: disabledInput,
            error: invalidInputLocking,
            min: minValue,
            value: inputValueLocking
          }}>Mint locking WBTC</ButtonWithInput></p>
          <LoadingOrErrorOrNothing loading={mintLockingLoading} error={errorTextLocking} loadingText={"Pending transaction..."} />
        </div>

        <div className={styles.or}>
          <p>Or:</p>
        </div>


        <div className={styles.secondChoice}>
          <h2><i><b>IRREVERSIBLE</b></i> WBTC to NFT conversion</h2>
          <p>BURN some WBTC FOREVER with our contract and get a different NFT.  You will NEVER get the WBTC back.</p>
          <p><ButtonWithInput cta={true} disabled={disabledButtonSacrificing} onClick={() => {
            setChoice("right");
            dispatch(mintScrificingWbtcAsync({ sats: btcToSats(inputValueSacrificing) }));
          }} input={{
            cta: true,
            onChange: (value: number) => setInputValueSacrificing(value),
            disabled: disabledInput,
            error: invalidInputSacrificing,
            min: minValue,
            value: inputValueSacrificing
          }}>Mint BURNING WBTC</ButtonWithInput></p>
          <LoadingOrErrorOrNothing loading={mintSacrificingLoading} error={errorTextSacrificing} loadingText={"Pending transaction..."} />
        </div>
      </div>
      <NftDisplay useAppSelector={useAppSelector} contractAddress={CONTRACT_ADDRESSES[network]} />
    </div>
  );
}
