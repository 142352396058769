import { approveAsync, connectWalletAsync, getAllowanceAsync } from "@cyberpnk/component-library";
import { Action } from "redux";
import { ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";
import { getBtcNftContract, getWbtcContract } from "../../app/common";
import { getTotalLockedAsync, getTotalSacrificedAsync, mintLockingWbtcAsync, mintScrificingWbtcAsync, unlockWbtcBurningNftAsync } from "./btcNftFSlice";

export const btcNftEpic = (action$: any) => action$.pipe(
  ofType(connectWalletAsync.fulfilled as Action, approveAsync.fulfilled, mintScrificingWbtcAsync.fulfilled, mintLockingWbtcAsync.fulfilled, unlockWbtcBurningNftAsync.fulfilled),
  switchMap(() => [
    getTotalLockedAsync(),
    getTotalSacrificedAsync(),
    getAllowanceAsync({ getContract: getWbtcContract, getSpenderContract: getBtcNftContract })
  ]),
);
