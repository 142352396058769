import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { approveReducer, connectWalletReducer, hasWalletAsync, listenForNetworkChange, listenForWalletChange, nftDisplayReducer } from '@cyberpnk/component-library';
import { btcNftReducer } from '../features/BtcNft/btcNftFSlice';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { btcNftEpic } from '../features/BtcNft/btcNftEpic';

const epicMiddleware = createEpicMiddleware();

export const store = configureStore({
  reducer: {
    connectWallet: connectWalletReducer,
    btcNft: btcNftReducer,
    nftDisplay: nftDisplayReducer,
    approve: approveReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(epicMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

store.dispatch(hasWalletAsync());
store.dispatch(listenForWalletChange());
store.dispatch(listenForNetworkChange());

export const rootEpic = combineEpics(
  btcNftEpic
);

epicMiddleware.run(rootEpic);
