import { ContractPerNetwork, Network } from "@cyberpnk/component-library";
import { BtcNft as addressBtcNftRinkeby, Wbtc as addressWbtcRinkeby } from "../contract-addresses-rinkeby.json";
import { BtcNft as addressBtcNftMainnet, Wbtc as addressWbtcMainnet } from "../contract-addresses-mainnet.json";
import {abi as BtcNftABI} from "../BtcNft.json";
import {abi as WbtcABI} from "../ERC20.json";
import Web3 from "web3";
import { AbiItem } from "web3-utils";

declare global {
  interface Window { ethereum: any; }
}

export const CONTRACT_ADDRESSES = {
  [Network.Rinkeby]: addressBtcNftRinkeby,
  [Network.Mainnet]: addressBtcNftMainnet,
}

export const WBTC_CONTRACT_ADDRESSES = {
  [Network.Rinkeby]: addressWbtcRinkeby,
  [Network.Mainnet]: addressWbtcMainnet,
}

let btcNftContract: ContractPerNetwork = {};
export const getBtcNftContract = async () => {
  const web3 = new Web3(window.ethereum);
  const network = await web3.eth.net.getNetworkType();
  const btcNftAddress = CONTRACT_ADDRESSES[network as Network];
  if (!btcNftContract[network]) {
    btcNftContract[network] = await new web3.eth.Contract(BtcNftABI as AbiItem[], btcNftAddress);
  }
  return btcNftContract[network];
}

let wbtcContract: ContractPerNetwork = {};
export const getWbtcContract = async () => {
  const web3 = new Web3(window.ethereum);
  const network = await web3.eth.net.getNetworkType();
  const wbtcAddress = WBTC_CONTRACT_ADDRESSES[network as Network];
  if (!wbtcContract[network]) {
    wbtcContract[network] = await new web3.eth.Contract(WbtcABI as AbiItem[], wbtcAddress);
  }
  return wbtcContract[network];
}
